<template>
    <div class="student-contain">
        <div class="left-box">
            <div class="class-box">
                <swiper ref="swiper_box" class="class-box-item" :options="swiperOption">
                    <swiper-slide class="siwper-item" v-for="item in ClassList" :key="item.class_id">
                        <div class="item-content">
                            <el-button :class="currentClass === item.class_id ?'active':''"
                                       @click="getStudentData(item)">{{item.name}}
                            </el-button>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="banner-swiper-btn-prev" v-show="ClassList.length > 0">
                    <div class="change-btn">
                        <i class="el-icon-arrow-left"></i>
                    </div>
                </div>
                <div class="banner-swiper-btn-next" v-show="ClassList.length > 0">
                    <div class="change-btn">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
            <!--            <el-tree :data="ClassList" :props="defaultProps" @node-click="handleNodeClick"></el-tree>-->
        </div>
        <div class="right-box">
            <el-table
                    :data="tabelList"
                    class="college-table"
                    height="100"
                    size="medium"
                    :header-cell-style="{fontWeight: '400', color: '#333', background: '#F8F8FD',height:'50px'}"
                    :cell-style="{fontSize: '16px',color: '#333',fontWeight: 'bold'}"
                    :row-style="{height:'100px'}"
            >
                <el-table-column
                        align="center"
                        prop="rank"
                        label="排行"
                        width="150">
                    <template slot-scope="scope">
                        <div class="icon-background" v-if="scope.row.index < 4">
                            <img src="../../../assets/img/image/StudentRank1.jpg"
                                 v-if="scope.row.index === 1" alt="">
                            <img src="../../../assets/img/image/StudentRank2.png"
                                 v-if="scope.row.index === 2" alt="">
                            <img src="../../../assets/img/image/StudentRank3.png"
                                 v-if="scope.row.index === 3" alt="">
                        </div>
                        <div class="rank-title" v-else>{{scope.row.index}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="user_name"
                        label="学生姓名"
                        align="left"
                        width="300"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <div class="account-number" @click="gotoData(scope.row)">
                            <img :src="scope.row.head_portrait ===''?require('../../../assets/img/image/blank_headPic.png'):scope.row.head_portrait"
                                 alt="" width="30"
                                 height="30">
                            <span class="scope-title">{{scope.row.name}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="video_total_likes" label="完成任务数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.rw_count}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="video_total_comments" label="粉丝数" align="center"
                                 class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.fans_count}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="video_total_forwarding" label="作品总数" align="center"
                                 class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.zp_count}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="video_total_forwarding" label="点赞总数" align="center"
                                 class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.dz_count}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="icon-box">
                            <div class="icon-content" @click="gotoData(scope.row)">
                                <i class="iconfont">&#xe676;</i>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="circle-page"
                    :current-page="currentPageNum"
                    :page-size="eachPageNum"
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="classCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import {mapActions, mapMutations} from 'vuex'

    export default {
        name: 'StudentData',
        components: {
            Swiper,
            SwiperSlide
        },
        directives: {
            swiper: directive
        },
        data() {
            return {
                ClassList: [],
                taskList: [],
                tabelList: [],
                unique_id: "",
                swiperOption: {
                    // observer: true,
                    // observeParents: true,
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                    slidesPerGroup: 3,
                    initialSlide: -1,
                    loopFillGroupWithBlank: true,
                    navigation: {
                        nextEl: '.banner-swiper-btn-next',
                        prevEl: '.banner-swiper-btn-prev',
                    },
                },
                currentClass: "",
                className: "",
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0
            }
        },
        created() {
            this.getList();
        },
        methods: {
            ...mapMutations(['setAccountInfo']),
            getList() {
                this.$httpteacher.axiosGetBy(this.$api.getClassList, {}, res => {
                    if (res.code == 200) {
                        this.ClassList = res.info;
                        if (this.ClassList.length > 0) {
                            this.currentClass = this.ClassList[0].class_id;
                            this.className = this.ClassList[0].name;
                            this.getStudentInfo(this.currentClass);
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            getStudentData(item) {
                this.currentClass = item.class_id;
                this.className = item.name;
                this.getStudentInfo(item.class_id)
            },
            getStudentInfo(id) {
                this.tabelList = [];
                let param = {
                    class_id: id,
                    page: this.currentPageNum,
                    limit: this.eachPageNum,
                }
                this.$httpteacher.axiosGetBy(this.$api.account_userListStatistics, param, res => {
                    if (res.code == 200) {
                        this.tabelList = res.data.data;
                        this.total = res.data.total;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            gotoData(row) {
                let obj = {
                    account_id: row.id,
                    class_id: this.currentClass,
                    class_name: this.className
                }
                console.log(obj)
                this.setAccountInfo(obj)
                this.$router.push('/teacher/account/details')
            },
            classCurrentChange(val) {
                this.currentPageNum = val;
                this.getStudentInfo(this.currentClass)
            },
        }
    }
</script>

<style scoped lang="scss">
    .student-contain {
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;

        .left-box {
            width: 100%;
            background-color: #ffffff;

            .class-box {
                padding: 20px 70px;
                position: relative;

                ::v-deep .el-button {
                    border-color: #D2D2D2;

                    &:hover {
                        color: #ffffff;
                        background-color: #564CED;
                    }

                    &.active {
                        color: #ffffff;
                        background-color: #564CED;
                    }

                }

                .banner-swiper-btn-prev {
                    position: absolute;
                    left: 24px;
                    top: 32px;
                    color: #BDBDBD;
                    cursor: pointer;

                    &:focus {
                        outline: none;
                    }

                    i {
                        font-weight: bold;
                        font-size: 18px;
                    }
                }

                .banner-swiper-btn-next {
                    position: absolute;
                    right: 24px;
                    top: 32px;
                    color: #BDBDBD;
                    cursor: pointer;

                    &:focus {
                        outline: none;
                    }

                    i {
                        font-weight: bold;
                        font-size: 18px;
                    }
                }
            }

            ::v-deep .siwper-item {
                width: unset;
                margin-right: 20px;
            }
        }

        .right-box {
            width: 100%;
            margin-top: 28px;
            flex: 1;
            height: 1%;
            display: flex;
            flex-direction: column;
            background-color: #ffffff;

            .college-table {
                flex: 1;
                height: 1%;
                width: 100%;

                .icon-background {
                    img {
                        width: 34px;
                        height: 32px;
                    }
                }


                .account-number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 45px;

                    img {
                        height: 100%;
                        width: 45px;
                        border-radius: 50%;
                    }

                    .scope-title {
                        width: 260px;
                        margin-left: 10px;
                        text-align: left;
                        color: #333;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: inline-block;
                        cursor: pointer;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                .icon-box {
                    display: flex;
                    justify-content: center;

                    .icon-content {
                        width: 40px;
                        height: 40px;
                        background: #FF6A6A;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            font-size: 20px;
                            color: #ffffff;
                        }
                    }
                }
            }

            .circle-page {
                padding: 35px;
            }
        }
    }
</style>